import { Alert, Dialog, Tooltip } from "@mui/material"
import style from "../IscrizioniTrek/IscrizioniTrek.module.css"
import { useNavigate } from "react-router-dom"
import InputBilo from "./InputBilo"
import ButtonCustom from "./ButtonCustom"
import LoadingComponent from "./LoadingComponent"
import noImage from "../assets/img/no_image.jpg";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useState } from "react"
import { api } from "../conf/apiConf"
import { useSelector } from "react-redux"
import axios from "axios"
import DialogCustom from "./DialogCustom"

const TableIscrizione = ({ tableData, isLoading = true, type = 'trek', deleteRegistration = () => { }, proceedRegistration = () => { }, payFee = () => { } }) => {

    const navigate = useNavigate()
    const count = useSelector(state => state.count)
    const [openModalPreferenze, setOpenModalPreferenze] = useState(false)
    const [usersPreferenze, setUserPreferenze] = useState(false)
    const [bedType, setBedType] = useState('Singolo')


    function formatData(value) {
        let formattedDate = ''
        if (value.data) {
            let data = value.data.split(" ")[0]
            let day = `${data.split('-')[2]}`
            let month = `${(data.split('-')[1])}`
            let year = data.split('-')[0]
            formattedDate = `${day}-${month}-${year}`
        }
        return formattedDate
    }

    return (
        !isLoading && tableData && tableData.length === 0 ?
            <Alert severity="info">{`Info - Nessuna iscrizione ${type ? (type === 'eventi' ? 'agli' : 'ai') : ''} ${type ? type : ''}`}</Alert>
            : (
                !isLoading && tableData && tableData.length > 0 ?
                    tableData.map((value, index) => {
                        return (
                            <div key={index} className={style.evento + " grid grid-cols-2 px-[2.5rem] ml-2 sm:ml-[2.5rem]" + (false ? " opacity-30" : " opacity-100")}>
                                <div className='grid grid-cols-1 sm:grid-cols-2 min-[940px]:!grid-cols-3'>
                                    <div onClick={() => { navigate(`/${type}/${value.internal_id}`) }} className={style['img-evento'] + ' self-end hover:cursor-pointer hover:opacity-80'} ><img src={value.image_path ? value.image_path : noImage} alt="" /></div>
                                    <div className={style['info']}>
                                        {
                                            type === 'trek' ?
                                                <>
                                                    <div>
                                                        <InputBilo className={style['nome'] + ' text-xl'} fontWeight={700} title={value.descrizione} />
                                                        <InputBilo className={style['descrizione'] + ' text-lg'} title={`Sez. ${value.nome}`} />
                                                    </div>
                                                    <div className={"text-lg mb-1 flex items-center gap-2"} >
                                                        <CalendarMonthOutlinedIcon />
                                                        <div>
                                                            <InputBilo className={style['data'] + ' text-base'} title={'Partenza: '} />
                                                            <InputBilo className={style['data'] + ' text-l mb-1'} fontWeight={700} title={formatData(value)} />
                                                            <InputBilo className={'text-xs'} fontWeight={700} title={'DURATA: ' + value.durata + `${value.durata === 1 ? ' GIORNO' : ' GIORNI '}`} />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div>
                                                        {/* <div className={style['nome'] + " text-xl"} style={{ fontFamily: "Bilo", fontWeight: 700 }}>{type === 'eventi' ? 'EVENTO' : 'CORSO'}</div> */}
                                                        <div className={style['descrizione'] + " text-2xl"} style={{ fontFamily: "Bilo", fontWeight: 700 }}>{value.nome}</div>
                                                    </div>
                                                    {
                                                        type === 'eventi' ?
                                                            <div className={style['data'] + " text-lg mb-1"} style={{ fontFamily: "Bilo", fontWeight: 700 }}><CalendarMonthOutlinedIcon />{value ? `${value['date_event'].split('-')[2]}-${value['date_event'].split('-')[1]}-${value['date_event'].split('-')[0]}` : ''}</div>
                                                            :
                                                            <div className={style['data'] + " text-lg mb-1"} style={{ fontFamily: "Bilo", fontWeight: 700 }}><CalendarMonthOutlinedIcon />{value ? `${value['date_start'].split('-')[2]}-${value['date_start'].split('-')[1]}-${value['date_start'].split('-')[0]}` : ''}</div>
                                                    }
                                                </>
                                        }

                                    </div>
                                    <div className={style['prezzo-container'] + " text-l mx-0 min-[940px]:!mx-[10px] px-2 self-end "} style={{ fontFamily: "Bilo" }}>
                                        QUOTA DI PARTECIPAZIONE

                                        <div className='whitespace-nowrap '>
                                            {
                                                type === 'trek' && value['type_quote_participation'] === 'variable' ?
                                                    <>
                                                        Da <div
                                                            className={style['prezzo'] + " text-xl"}
                                                            style={{ fontFamily: "Bilo", fontWeight: 700 }}
                                                        >€ {value.prezzo1}</div> a <div
                                                            className={style['prezzo'] + " text-xl"}
                                                            style={{ fontFamily: "Bilo", fontWeight: 700 }}
                                                        >€ {value.prezzo2}</div>
                                                    </>
                                                    : (
                                                        value['type_quote_participation'] === 'free' || (type !== 'trek' && value.quota_richiesta === "0.00") ?
                                                            <InputBilo className={style['prezzo'] + " text-xl"}
                                                                fontWeight={700}
                                                                title={'GRATUITO'} />
                                                            :
                                                            <InputBilo className={style['prezzo'] + " text-xl"}
                                                                fontWeight={700}
                                                                title={'€ ' + (type === 'trek' ? (
                                                                    value.quote_trek === value.quota_richiesta ? value.prezzo2 : value.quota_richiesta
                                                                ) : value.quota_richiesta)} />
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    value.subscriber_status !== 'canceled' && value.subscriber_status !== 'waiting' ?
                                        value.totale_pagato === Number(value.quota_richiesta) ?
                                            <div className='flex gap-2 flex-col-reverse justify-between min-[1200px]:!grid min-[1200px]:!grid-cols-3'>
                                                <Tooltip title='Contatta Trekking per cancellare il trek'>
                                                    <ButtonCustom
                                                        className={"!border-b-0 h-[50px] min-w-[176px] max-w-[176px] p-2 self-center min-[1200px]:!self-end " + ((value.online_registration === 'si' || value.registration_methods === 'online') ? ' opacity-100' : ' opacity-30 hover:!opacity-30 cursor-not-allowed')}
                                                        bg={true}
                                                        onClickCustom={() => { deleteRegistration(value.online_registration === 'si' || value.registration_methods === 'online', type, value.subscription_id) }}>
                                                        <InputBilo className={'h-full text-[#000000] text-sm py-2'} title="CANCELLA L'ISCRIZIONE" />
                                                    </ButtonCustom>
                                                </Tooltip>
                                                <div className={style.acquistato + " text-3xl self-center min-[1200px]:!self-end col-start-3 "} style={{ fontFamily: "Bilo", fontWeight: 700 }}>ACQUISTATO</div>
                                            </div>
                                            :
                                            <div className='grid grid-cols-1 gap-2 min-[1200px]:!grid-cols-3 '>
                                                <Tooltip title='Contatta Trekking per cancellare il trek'>
                                                    <ButtonCustom
                                                        className={style['btn-cancella'] + " p-2 self-end justify-self-center min-[1200px]:!justify-self-start " + ((value.online_registration === 'si' || value.registration_methods === 'online') ? ' opacity-100' : ' opacity-30 hover:!opacity-30 !cursor-not-allowed')}
                                                        bg={true}
                                                        onClickCustom={() => { deleteRegistration(value.online_registration === 'si' || value.registration_methods === 'online', type, value.subscription_id) }}>
                                                        <InputBilo className={'h-full text-[#000000] text-sm py-2'} title="CANCELLA L'ISCRIZIONE" />
                                                    </ButtonCustom>
                                                </Tooltip>
                                                <ButtonCustom
                                                    bg={true}
                                                    onClickCustom={() => {
                                                        proceedRegistration(
                                                            (value.online_registration === 'si' || value.registration_methods === 'online'),
                                                            value.subscriber_status === 'reservation',
                                                            type,
                                                            value.id,
                                                            type === 'trek' ? { saldo_da_pagare: value.saldo_da_pagare, anticipo_da_pagare: value.anticipo_da_pagare } : ((value.quota_richiesta) - (value.totale_pagato)),
                                                            value.subscription_id,
                                                            value.advance_payment_methods,
                                                            value.balance_payment_methods)
                                                    }}
                                                    className={style['btn-pagamento'] + " p-2 self-end justify-self-center min-[1200px]:!justify-self-start " + ((value.online_registration === 'si' || value.registration_methods === 'online') && value.subscriber_status === 'reservation' ? " opacity-100" : " opacity-30 hover:!opacity-30 !cursor-not-allowed")}>
                                                    <InputBilo className={'h-full text-sm py-2'} title="PROCEDI ALL'ISCRIZIONE" />
                                                </ButtonCustom>
                                                <ButtonCustom
                                                    bg={true}
                                                    onClickCustom={() => {
                                                        payFee(
                                                            value.show_salda_button,
                                                            type,
                                                            value.id,
                                                            type === 'trek' ? { saldo_da_pagare: value.saldo_da_pagare, anticipo_da_pagare: value.anticipo_da_pagare } : ((value.quota_richiesta) - (value.totale_pagato)),
                                                            value.subscription_id,
                                                            value.advance_payment_methods,
                                                            value.balance_payment_methods
                                                        )
                                                    }}
                                                    className={style['btn-salda'] + " p-2 self-end justify-self-center min-[1200px]:!justify-self-start " + (value.show_salda_button ? " opacity-100" : " opacity-30 hover:!opacity-30 !cursor-not-allowed")}>
                                                    <InputBilo className={'h-full text-sm py-2'} title="SALDA LA QUOTA" />
                                                </ButtonCustom>
                                            </div>
                                        : (
                                            value.subscriber_status === 'canceled' ?
                                                <div className='grid grid-cols-1 gap-2 min-[1200px]:!grid-cols-3 '>
                                                    <div className={style.acquistato + " text-3xl col-start-3 self-end justify-self-start min-[1500px]:!justify-self-center"} style={{ fontFamily: "Bilo", fontWeight: 700 }}>CANCELLATO</div>
                                                </div>
                                                :
                                                <div className='grid grid-cols-1 gap-2 min-[1200px]:!grid-cols-3 '>
                                                    <div className={style.acquistato + " text-3xl col-start-3 self-end justify-self-start min-[1500px]:!justify-self-center"} style={{ fontFamily: "Bilo", fontWeight: 700 }}>IN LISTA D'ATTESA</div>
                                                </div>
                                        )
                                }
                                {
                                    type === 'trek' &&
                                    <div className="w-full col-start-2 grid grid-cols-1 gap-2 min-[1200px]:!grid-cols-3 mt-2">
                                        <button onClick={() => {
                                            const sharingRoom = JSON.parse(value?.sharing_room)
                                            const otherUsersIds = sharingRoom.filter(id => Number(id) !== Number(count.profile.id))


                                            const axiosArray = otherUsersIds.map(userId => api.get(`/users/get/${userId}`))
                                            // api.get(url).then((res) => {
                                            //     console.log(res)
                                            // })
                                            axios.all(axiosArray).then(res => {
                                                console.log(res)
                                                const users = res.map(data => data.data.data)
                                                console.log(users)
                                                setUserPreferenze(users)
                                                setOpenModalPreferenze(true)
                                                setBedType(value.bed_type ?? 'Singolo')
                                            }).catch(err => console.log(err))

                                        }} className="border border-black rounded-xl pl-4 pr-4 shadow-md mb-2 grid place-self-center min-[1200px]:!col-start-3 min-[1200px]:!place-self-start text-lg" >
                                            Preferenze camere
                                        </button>
                                        <DialogCustom
                                            title={"Preferenze"}
                                            open={openModalPreferenze}
                                            closeModal={() => { setOpenModalPreferenze(false) }}
                                            content={`Camera condivisa: ${usersPreferenze.length > 0 ? `Si, con ${usersPreferenze.map(user => user.name + ' ' + user.surname).join(', ')}` : 'No'}.\n\nLa tua preferenza è avere un letto ${bedType}`}
                                        />
                                    </div>
                                }

                            </div>
                        )
                    })
                    : <LoadingComponent />
            )
    )
}

export default TableIscrizione